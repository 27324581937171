import { Drive } from "common/types.ts";
import { get } from "common/helpers/HTTP.ts";
import { useQuery } from "@tanstack/react-query";
import { dummyDrives, getDummyPlace } from "@/src/dummyGenerator.ts";
import { getLocalTimeZone, today } from "@internationalized/date";

async function getDrives(
  placeId: string,
  date: string | undefined,
): Promise<Drive[]> {
  if (getDummyPlace(placeId)) {
    return date === today(getLocalTimeZone()).toString() ? dummyDrives : [];
  }
  const searchParams = new URLSearchParams();
  if (date) {
    searchParams.set("date", date);
  }
  const { drives } = await get(
    `v1/places/${placeId}/drives?${searchParams.toString()}`,
  );
  return drives;
}
export default function useDrives(placeId: string, date: string) {
  const { data: drives, isLoading: isLoadingDrives } = useQuery({
    queryKey: ["drives", date],
    queryFn: () => getDrives(placeId, date),
  });
  return { drives, isLoadingDrives };
}
