import { Badge } from "common/components/base";

import { useParams } from "@tanstack/react-router";
import ArrowUpRightDots from "fontawesome/solid/arrow-up-right-dots.svg?react";
import Tag from "fontawesome/solid/tag.svg?react";
import Calendar from "fontawesome/solid/calendar.svg?react";
import Cars from "fontawesome/solid/cars.svg?react";
import useAllSettings from "common/datahooks/useAllSettings.ts";
import usePlace from "common/datahooks/usePlace.tsx";

export default function PlanSettings() {
  const { placeId } = useParams({ from: "/$placeId" });
  const { allSettings } = useAllSettings();
  const { place } = usePlace(placeId);

  if (allSettings && place) {
    return (
      <div className="flex flex-wrap gap-1">
        <Badge size="md" variant="purple">
          <ArrowUpRightDots />
          Grid size:
          {
            allSettings.grid_size.options.find(
              ({ id }) => id === place.settings.grid_size,
            )?.label
          }
        </Badge>
        <Badge size="md" variant="purple">
          <Tag />
          Keyword number: {place.settings.keyword_count}
        </Badge>
        <Badge size="md" variant="purple">
          <Calendar />
          Frequency: {place.settings.schedule.length} day/s
        </Badge>
        <Badge size="md" variant="purple">
          <Cars />
          Daily drives: {place.settings.drives}
        </Badge>
      </div>
    );
  }
}
