import CircleCheck from "fontawesome/solid/circle-check.svg?react";
import { Button } from "common/components/base";
import { useAppStore, useTwBreakpoint } from "common/hooks";

import SubscriptionGraphic from "@/src/assets/subscription-graphic.svg?react";
import usePlanPricing from "common/datahooks/usePlanPricing.tsx";

const features = [
  "Up to 100 Keywords",
  "Up to 15x15 Grid view",
  "Up to 1000 Daily Drives",
  "Grid and Drives Analytics",
];
export default function NoSubscriptionPlaceholder() {
  const { planPricing } = usePlanPricing();
  const isDesktop = useTwBreakpoint("lg");

  return (
    <div className="relative z-base flex flex-col justify-between overflow-hidden rounded-4xl bg-grey-900 px-4 py-5 lg:flex-row lg:rounded-7xl lg:px-6 lg:py-7">
      <SubscriptionGraphic className="absolute inset-y-0 -right-1/2 -z-base h-full max-lg:left-1/2 lg:right-[20%] lg:top-4" />
      <div className="flex flex-col max-lg:mb-12">
        <div className="flex flex-col md:max-w-[50%] 2xl:max-w-[40%]">
          <span className="mb-1 text-3xl font-medium text-green-100 lg:text-white">
            Build Your Custom Plan
          </span>
          <p className="mb-8 text-base text-grey-300">
            Customize your plan to fit your business needs. Adjust grid size,
            daily drives, and target keywords to optimize your results and stay
            ahead of the competition
          </p>
        </div>
        <div className="flex flex-col max-lg:gap-y-3 lg:flex-row lg:gap-x-6">
          {features.map((feature) => (
            <div
              key={feature}
              className="flex items-center gap-x-2 text-base font-medium text-white"
            >
              <CircleCheck className="size-4 fill-green-400 lg:fill-purple-400" />
              {feature}
            </div>
          ))}
        </div>
      </div>
      <div className="flex items-end justify-between lg:flex-col">
        <div className="flex gap-x-1.5">
          <span className="self-end text-xl text-grey-300">from</span>
          {planPricing && (
            <span className="text-3xl font-semibold text-white lg:text-6xl">
              ${planPricing.month}
            </span>
          )}
          <span className="self-start text-grey-300">/month</span>
        </div>
        <Button
          variant={isDesktop ? "green" : "white"}
          size="md"
          onPress={() => useAppStore.getState().setIsAddModalOpen(true)}
        >
          Start free trial
        </Button>
      </div>
    </div>
  );
}
