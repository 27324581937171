import {
  Slider,
  Select,
  NumberInput,
  DropdownBoxItem,
  DropdownBox,
} from "common/components/base";
import SettingsItem from "common/components/GridAndDrivesSettings/SettingsItem";
import useAllSettings from "common/datahooks/useAllSettings";
import { PaidAndFreePlaceSettings } from "common/types.ts";
import useCustomer from "common/datahooks/useCustomer.ts";

export default function DrivesSettings({
  settings,
  setSettingsValue,
}: {
  settings: PaidAndFreePlaceSettings | undefined;
  setSettingsValue: <T extends keyof PaidAndFreePlaceSettings>(
    key: T,
    value: PaidAndFreePlaceSettings[T],
  ) => void;
}) {
  const { customer } = useCustomer();
  const { allSettings } = useAllSettings();

  const settingsItems = [
    {
      label: "Daily drives",
      description:
        allSettings &&
        `$${allSettings.drives.unit_price / 100} / ${allSettings.drives.unit} daily drives`,
      component: (
        <NumberInput
          size="lg"
          maxValue={allSettings?.drives.max}
          minValue={allSettings?.drives.min}
          step={allSettings?.drives.unit}
          value={settings?.drives}
          onChange={(newValue) => setSettingsValue("drives", newValue)}
        />
      ),
      isPremium: true,
    },
    {
      label: "Drives working hours",
      component: (
        <Select
          size="lg"
          className="w-52"
          placeholder="Select working hours"
          selectedKey={settings?.drives_working_hours}
          onSelectionChange={(key) =>
            setSettingsValue("drives_working_hours", key as string)
          }
        >
          <DropdownBox size="lg">
            {(allSettings ? allSettings.drives_working_hours.options : []).map(
              ({ id, label }) => (
                <DropdownBoxItem key={id} id={id} size="lg" title={label} />
              ),
            )}
          </DropdownBox>
        </Select>
      ),
      isColumn: true,
    },
    {
      label: "Radius size",
      component: (
        <div className="mt-2 flex flex-col gap-y-4">
          <Slider
            size="lg"
            maxValue={allSettings?.drives_radius.max}
            minValue={allSettings?.drives_radius.min}
            step={allSettings?.drives_radius.unit}
            value={settings?.drives_radius}
            onChange={(newValue) => setSettingsValue("drives_radius", newValue)}
          />
          <NumberInput
            size="sm"
            className="ml-auto w-36"
            maxValue={allSettings?.drives_radius.max}
            minValue={allSettings?.drives_radius.min}
            step={allSettings?.drives_radius.unit}
            value={settings?.drives_radius}
            onChange={(newValue) => setSettingsValue("drives_radius", newValue)}
            formatOptions={{
              style: "unit",
              unit:
                customer && customer.preferred_unit === "Meters"
                  ? "meter"
                  : "foot",
            }}
          />
        </div>
      ),
      isColumn: true,
    },
  ];

  return settingsItems.map((props) => {
    return <SettingsItem {...props} />;
  });
}
