import {
  BillingFrequency,
  OptionsSettingsItem,
  PaymentEstimate,
  PlanSettings,
  AllSettings,
} from "common/types.ts";
import usePaymentEstimate from "common/datahooks/usePaymentEstimate.ts";

import Coins from "fontawesome/solid/coins.svg?react";
import AlertMessage from "common/components/PlanCustomization/AlertMessage.tsx";
import { useParams } from "@tanstack/react-router";
import usePlace from "common/datahooks/usePlace.tsx";
import useSubscription from "common/datahooks/useSubscription.ts";
import { fromAbsolute, getLocalTimeZone } from "@internationalized/date";
import { clsx } from "clsx";
import { getMonthName, getWeekDay } from "common/utils.ts";
import useNextPaymentEstimate from "common/datahooks/useNextPaymentEstimate.tsx";

export default function PlanPrice({
  selectedPlanDetails,
  allSettings,
  billingFrequency,
}: {
  selectedPlanDetails: PlanSettings | undefined;
  allSettings: AllSettings | undefined;
  billingFrequency: BillingFrequency;
}) {
  const { placeId } = useParams({ from: "/$placeId" });
  const { place } = usePlace(placeId);
  const { subscription } = useSubscription(placeId);
  const { paymentEstimate } = usePaymentEstimate(
    placeId,
    billingFrequency,
    selectedPlanDetails,
  );
  const { nextPaymentEstimate } = useNextPaymentEstimate(placeId);

  const placeSettings = place?.settings;

  const pricingItems: {
    key: keyof PaymentEstimate;
    label: string;
    getValue: (planSettings: PlanSettings) => string | number;
  }[] = allSettings
    ? [
        {
          key: "grid_size",
          label: "Grid size",
          getValue: (planDetails) =>
            (
              allSettings.grid_size.options.find(
                ({ id }) => id === planDetails.grid_size,
              ) as OptionsSettingsItem["options"][number]
            ).value,
        },
        {
          key: "keyword_count",
          label: "Keywords",
          getValue: (planDetails) => planDetails.keyword_count,
        },
        {
          key: "schedule",
          label: "Frequency",
          getValue: (planDetails) =>
            `${planDetails.schedule.length} Day${planDetails.schedule.length > 1 ? "s" : ""}`,
        },
        {
          key: "drives",
          label: "Daily drives",
          getValue: (planDetails) => planDetails.drives,
        },
      ]
    : [];

  let nextPaymentDateComponent;
  if (paymentEstimate && subscription) {
    const nextBillingDate = fromAbsolute(
      paymentEstimate.billing_date * 1000,
      getLocalTimeZone(),
    );
    nextPaymentDateComponent = (
      <>
        <span className="capitalize">{getWeekDay(nextBillingDate)}</span>{" "}
        <span
          className={clsx(
            "font-semibold",
            subscription.status === "in_trial" && "text-purple-500",
          )}
        >
          {nextBillingDate.day} {getMonthName(nextBillingDate)},{" "}
          {nextBillingDate.year}
        </span>
      </>
    );
  }
  return (
    <>
      <div className="my-3 flex flex-col gap-y-3">
        {pricingItems.map(({ key, label, getValue }) => {
          const previousValue = placeSettings && getValue(placeSettings);
          const currentValue =
            selectedPlanDetails && getValue(selectedPlanDetails);

          return (
            <div key={label} className="mx-2 flex text-sm">
              <span className="font-medium text-grey-500">{label}</span>
              <span className="ml-auto font-medium text-grey-500">
                {previousValue &&
                  currentValue !== previousValue &&
                  `${previousValue} -> `}
                {currentValue}
              </span>
              <div className="flex w-14 items-center justify-end font-semibold">
                {paymentEstimate ? (
                  `$${paymentEstimate[key] / 100}`
                ) : (
                  <div className="h-5 w-10 animate-pulse rounded-full bg-grey-300" />
                )}
              </div>
            </div>
          );
        })}
      </div>

      <div className="mx-2 mb-3 flex items-center justify-between border-t border-t-grey-200 pt-1">
        <span className="text-sm font-medium text-grey-500">
          Subscription price:
        </span>
        <div className="flex items-center gap-x-1">
          {subscription &&
            paymentEstimate &&
            nextPaymentEstimate &&
            nextPaymentEstimate.total !== paymentEstimate.total && (
              <span className="text-sm font-medium text-grey-500">
                ${nextPaymentEstimate.total / 100}
                {billingFrequency !== subscription.period &&
                  `/${subscription.period}`}{" "}
                {"->"}
              </span>
            )}
          {paymentEstimate ? (
            <span className="text-2xl font-semibold">
              ${paymentEstimate.total / 100}
            </span>
          ) : (
            <div className="h-8 w-14 animate-pulse rounded-full bg-grey-300" />
          )}
          <span className="text-sm font-medium text-grey-500">
            / {billingFrequency}
          </span>
        </div>
      </div>
      {nextPaymentDateComponent ? (
        <p className="text-sm">
          {subscription?.status !== "in_trial" ? (
            <>Your next payment is on {nextPaymentDateComponent}</>
          ) : (
            <>
              Your{" "}
              <span className="font-semibold text-purple-500">free trial</span>{" "}
              ends on {nextPaymentDateComponent}, and your payment will
              automatically renewed.
            </>
          )}
        </p>
      ) : (
        <div className="h-5 w-48 animate-pulse rounded-full bg-grey-300" />
      )}
      <AlertMessage
        isTrial={!!subscription && subscription.status === "in_trial"}
        hasTokens={!!paymentEstimate?.tokens}
      />
      <div className="mt-auto">
        {paymentEstimate && (
          <div className="my-4 flex items-center justify-between pt-4 max-lg:border-t max-lg:border-t-grey-300 lg:mt-auto">
            <span className="text-sm font-medium">
              {paymentEstimate?.tokens ? "Total" : "To pay now:"}
            </span>
            <div className="flex items-center gap-x-1 text-2xl font-semibold">
              {paymentEstimate.tokens
                ? `+${paymentEstimate.tokens / 100}`
                : `$${paymentEstimate.pay_now / 100}`}
              {!!paymentEstimate.tokens && (
                <Coins className="size-6 fill-purple-500" />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
