import { Button } from "common/components/base";

import PaymentMethod from "@/src/pages/settings/PaymentMethod.tsx";
import NoSubscriptionPlaceholder from "@/src/pages/settings/NoSubscriptionPlaceholder.tsx";
import { useAppStore, useTwBreakpoint } from "common/hooks";
import useSubscription from "common/datahooks/useSubscription.ts";
import { clsx } from "clsx";
import PlanSettings from "@/src/pages/settings/PlanSettings.tsx";
import { useParams } from "@tanstack/react-router";
import SubscriptionSummary from "@/src/pages/settings/SubscriptionSummary.tsx";

export default function Subscription() {
  const isDesktop = useTwBreakpoint("lg");
  const { placeId } = useParams({ from: "/$placeId" });
  const { subscription, isLoadingSubscription } = useSubscription(placeId);

  let subscriptionDetailsComponent,
    manageSubscriptionComponent,
    subscriptionName;
  if (isLoadingSubscription) {
    subscriptionDetailsComponent = (
      <div className="h-5 w-20 animate-pulse rounded-full bg-grey-200" />
    );
  } else if (subscription) {
    subscriptionDetailsComponent = <PlanSettings />;
    const { status } = subscription;
    manageSubscriptionComponent = (
      <Button
        variant={status === "cancelled" ? "green" : "black"}
        size={isDesktop ? "lg" : "md"}
        onPress={() => useAppStore.getState().setIsPlanCustomizationOpen(true)}
      >
        {status === "cancelled" ? "Subscribe again" : "Manage subscription"}
      </Button>
    );
    if (subscription.status === "cancelled") {
      subscriptionName = "Cancelled";
    } else if (subscription.status === "in_trial") {
      subscriptionName = "Free trial";
    } else {
      subscriptionName = "Pro plan";
    }
  } else {
    subscriptionDetailsComponent = (
      <span className="text-sm font-medium text-grey-500">Demo</span>
    );
  }

  const subscriptionHeaderComponent = (
    <div className="flex flex-col items-baseline gap-x-1.5 lg:flex-row">
      <h3 className="text-lg font-semibold lg:text-xl">Subscription: </h3>
      {subscription && (
        <>
          <span
            className={clsx(
              "text-xl font-semibold",
              subscription.status === "in_trial" && "text-purple-500",
            )}
          >
            {subscriptionName}
          </span>
          {subscription.status !== "cancelled" && (
            <span className="text-sm font-medium text-grey-500">
              {subscription.period === "month" ? "Monthly" : "Yearly"} plan
            </span>
          )}
        </>
      )}
    </div>
  );

  return (
    <div className="flex flex-col rounded-4xl bg-grey-50 lg:rounded-7xl">
      <div className="flex flex-col p-3 lg:p-6 lg:pb-5">
        {isDesktop ? (
          <div className="flex items-center justify-between">
            <div className="flex flex-col gap-y-1">
              {subscriptionHeaderComponent}
              {subscriptionDetailsComponent}
            </div>
            {manageSubscriptionComponent}
          </div>
        ) : (
          <div className="flex flex-col gap-y-2">
            <div className="flex justify-between">
              {subscriptionHeaderComponent}
              {manageSubscriptionComponent}
            </div>
            {subscriptionDetailsComponent}
          </div>
        )}
        <div className="mt-2 flex flex-col justify-between border-t border-t-grey-200 pt-2 max-lg:gap-y-2 lg:mt-4 lg:flex-row lg:items-center lg:pt-4">
          <SubscriptionSummary />
          {subscription && subscription.status !== "cancelled" && (
            <span className="text-xs text-grey-500">
              Your plan will automatically renew
            </span>
          )}
        </div>
      </div>
      {subscription ? (
        <PaymentMethod
          paymentSourceId={subscription.payment_source_id}
          isLoading={isLoadingSubscription}
        />
      ) : (
        <NoSubscriptionPlaceholder />
      )}
    </div>
  );
}
