import { useQuery } from "@tanstack/react-query";
import { AllSettings } from "common/types.ts";
import { get } from "@/src/common/helpers/HTTP.ts";
import useCustomer from "common/datahooks/useCustomer.ts";
import { metersToFeet } from "common/utils.ts";
import { useMemo } from "react";

async function getAllSettingsRequest(): Promise<AllSettings> {
  const { possible_values } = await get(`v1/billing/values`);
  // this is supposed to be temporary
  return {
    ...possible_values,
    schedule: possible_values.frequency,
    drives: possible_values.daily_drives,
    keyword_count: possible_values.keywords,
  };
}
export default function useAllSettings() {
  const { customer } = useCustomer();
  const { data: allSettings, isLoading: isLoadingAllSettings } = useQuery({
    queryKey: ["allSettings"],
    refetchOnMount: false,
    queryFn: () => getAllSettingsRequest(),
  });

  const modifiedSettings = useMemo(() => {
    if (allSettings) {
      if (customer && customer.preferred_unit === "Feet") {
        return {
          ...allSettings,
          drives_radius: {
            min: metersToFeet(allSettings.drives_radius.min),
            max: metersToFeet(allSettings.drives_radius.max),
            unit: metersToFeet(allSettings.drives_radius.unit),
            unit_price: metersToFeet(allSettings.drives_radius.unit_price),
            default_value: metersToFeet(
              allSettings.drives_radius.default_value,
            ),
          },
          grid_distance: {
            min: metersToFeet(allSettings.grid_distance.min),
            max: metersToFeet(allSettings.grid_distance.max),
            unit: metersToFeet(allSettings.grid_distance.unit),
            unit_price: metersToFeet(allSettings.grid_distance.unit_price),
            default_value: metersToFeet(
              allSettings.grid_distance.default_value,
            ),
          },
        };
      } else {
        return allSettings;
      }
    }
  }, [customer, allSettings]);
  return { allSettings: modifiedSettings, isLoadingAllSettings };
}
