import { ReactNode, useMemo, useState } from "react";
import {
  DropdownBox,
  DropdownBoxItem,
  Pagination,
  Select,
} from "common/components/base";
import { RankedPlace } from "common/types.ts";
import PlacesListItem from "@/src/pages/home/PlacesList/PlacesListItem.tsx";

import BarsFilter from "fontawesome/regular/bars-filter.svg?react";
import { usePagination } from "common/hooks";

const sortOptions: {
  label: string;
  sortFn: (a: RankedPlace, b: RankedPlace) => number;
}[] = [
  {
    label: "Best Rank",
    sortFn: (placeA, placeB) => {
      if (placeA.average_rank === null) {
        return 1;
      } else if (placeB.average_rank === null) {
        return -1;
      } else {
        return placeA.average_rank - placeB.average_rank;
      }
    },
  },
  {
    label: "Best Rating",
    sortFn: (placeA, placeB) => {
      return placeB.rating - placeA.rating;
    },
  },
  {
    label: "Review Count",
    sortFn: (placeA, placeB) => {
      return placeA.rating_count.localeCompare(placeB.rating_count);
    },
  },
  {
    label: "Name",
    sortFn: (placeA, placeB) => {
      return placeA.name > placeB.name ? 1 : -1;
    },
  },
];
const defaultSelectedIndex = "0";
const placesPerPage = 7;
export default function PlacesList({
  places,
}: {
  places: RankedPlace[] | undefined;
}) {
  const [selectedFilterIndex, setSelectedFilterIndex] =
    useState<string>(defaultSelectedIndex);

  const sortedPlaces = useMemo(() => {
    return places
      ? [...places].sort(sortOptions[Number(selectedFilterIndex)].sortFn)
      : [];
  }, [places, selectedFilterIndex]);

  const { paginationComponentProps, pageItems } = usePagination(
    placesPerPage,
    sortedPlaces,
  );

  const components: ReactNode[] = [];
  let paginationComponent;

  if (places) {
    pageItems.forEach((place, index) => {
      components.push(<PlacesListItem place={place} key={index} />);
    });
    if (places.length > placesPerPage) {
      paginationComponent = (
        <Pagination
          {...paginationComponentProps}
          className="mx-auto mt-5"
          size="sm"
        />
      );
    }
  } else {
    for (let i = 0; i < placesPerPage; i++) {
      components.push(<PlacesListItem key={i} />);
    }
    paginationComponent = (
      <div className="mx-auto my-5 h-9 w-52 animate-pulse rounded-full bg-grey-300" />
    );
  }

  return (
    <>
      <div className="mb-5 flex items-center justify-between">
        <span className="text-lg font-semibold lg:text-xl">Other GBP</span>
        <div className="flex items-center gap-x-2">
          <Select
            aria-label="Sort by"
            selectedKey={selectedFilterIndex}
            onSelectionChange={(value) => {
              setSelectedFilterIndex(value.toString());
            }}
            size="md"
            placeholder="Sort by"
            leftIcon={<BarsFilter />}
            className="w-48"
          >
            <DropdownBox size="lg">
              {sortOptions.map(({ label }, index) => (
                <DropdownBoxItem
                  key={index}
                  id={index.toString()}
                  size="lg"
                  title={label}
                />
              ))}
            </DropdownBox>
          </Select>
        </div>
      </div>
      <div className="flex grow flex-col">{components}</div>
      {paginationComponent}
    </>
  );
}
