import { useEffect, useRef, useState } from "react";
import { MONTH_NAMES, RANKS, WEEK_DAYS } from "@/src/constants.tsx";
import {
  CalendarDate,
  CalendarDateTime,
  getDayOfWeek,
  ZonedDateTime,
} from "@internationalized/date";
export function getRankName(rank: number) {
  if (rank < 6) {
    return "high";
  } else if (rank < 11) {
    return "med";
  } else {
    return "low";
  }
}
export function getRankLabel(rank: number | null) {
  return rank ? rank : "21+";
}
export function getRankColorClassnames(rank: number | null) {
  if (rank) {
    return RANKS[getRankName(rank)].colorClassNames;
  }
  return RANKS.low.colorClassNames;
}
export function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState("");
  const timerRef = useRef<number>();

  useEffect(() => {
    timerRef.current = window.setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [value, delay]);

  return debouncedValue;
}
export function addLeadingZero(number: number) {
  return number < 9 ? `0${number}` : number.toString();
}
export function getWeekDay(
  date: CalendarDate | CalendarDateTime | ZonedDateTime,
) {
  return WEEK_DAYS[getDayOfWeek(date, "fr-FR")];
}
export function getMonthName(
  date: CalendarDate | CalendarDateTime | ZonedDateTime,
) {
  return MONTH_NAMES[date.month - 1];
}
export function formatDayAndMonth(date: CalendarDate) {
  return `${date.day} ${getMonthName(date)}, ${getWeekDay(date).slice(0, 3)}`;
}
//returns a positive number for that string
export function getNumberFromString(string: string) {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    const chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  hash = Math.abs(hash);
  return hash;
}
type Entries<T> = { [K in keyof T]: [K, T[K]] }[keyof T];
export function typedObjectEntries<T extends object>(t: T): Entries<T>[] {
  return Object.entries(t) as Entries<T>[];
}

export function metersToFeet(meters: number) {
  return Math.round(meters * 3.3);
}
export function feetToMeters(feet: number) {
  return Math.round(feet * 3.3);
}
