import { addLeadingZero, getMonthName, getWeekDay } from "common/utils.ts";
import {
  fromAbsolute,
  getLocalTimeZone,
  parseAbsolute,
} from "@internationalized/date";
import useNextPaymentEstimate from "common/datahooks/useNextPaymentEstimate.tsx";
import { useParams } from "@tanstack/react-router";
import useSubscription from "common/datahooks/useSubscription.ts";

export default function SubscriptionSummary() {
  const { placeId } = useParams({ from: "/$placeId" });
  const { nextPaymentEstimate } = useNextPaymentEstimate(placeId);
  const { subscription } = useSubscription(placeId);

  let mainContent;
  if (subscription) {
    const billingDurationLabel =
      subscription.period === "month" ? "monthly" : "yearly";

    const nextBillingDate = nextPaymentEstimate
      ? fromAbsolute(
          nextPaymentEstimate.billing_date * 1000,
          getLocalTimeZone(),
        )
      : undefined;

    if (subscription.status === "in_trial") {
      mainContent = (
        <>
          Your <span className="font-semibold text-purple-500">free trial</span>{" "}
          ends on{" "}
          {nextBillingDate && (
            <>
              {getWeekDay(nextBillingDate)}{" "}
              <span className="font-semibold text-purple-500">
                {nextBillingDate.day} {getMonthName(nextBillingDate)}
              </span>
            </>
          )}
          , then you will pay{" "}
          {nextPaymentEstimate ? (
            <span className="font-semibold text-purple-500">
              ${nextPaymentEstimate.total / 100}
            </span>
          ) : (
            <span className="h-4.5 w-10 animate-pulse rounded-full bg-grey-300" />
          )}{" "}
          {billingDurationLabel} on the same date
        </>
      );
    } else if (subscription.status === "cancelled") {
      const cancellationDate = parseAbsolute(
        subscription.cancelled_at,
        getLocalTimeZone(),
      );
      mainContent = (
        <>
          Your subscription was canceled on{" "}
          <span className="font-semibold">
            {getMonthName(cancellationDate)} {cancellationDate.day}
          </span>
          . To continue using HeyRank, simply resubscribe
        </>
      );
    } else {
      mainContent = (
        <>
          You’re subscribed to {billingDurationLabel} plan. Your next payment of{" "}
          {nextPaymentEstimate ? (
            <span className="font-semibold">
              ${nextPaymentEstimate.total / 100}
            </span>
          ) : (
            <span className="h-4.5 w-10 animate-pulse rounded-full bg-grey-300" />
          )}{" "}
          will be charged on{" "}
          {nextBillingDate ? (
            <span className="font-semibold">
              {getMonthName(nextBillingDate)}{" "}
              {addLeadingZero(nextBillingDate.day)}, {nextBillingDate.year}
            </span>
          ) : (
            <span className="h-4.5 w-36 animate-pulse rounded-full bg-grey-300" />
          )}
        </>
      );
    }
  }
  return <p className="text-sm">{mainContent}</p>;
}
