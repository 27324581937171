import { useQuery } from "@tanstack/react-query";
import { Subscription } from "common/types.ts";
import { get } from "@/src/common/helpers/HTTP.ts";
import { useAppStore } from "common/hooks";

async function getSubscriptionRequest(placeId: string): Promise<Subscription> {
  const { subscription } = await get(`v1/places/${placeId}`);
  return subscription;
}
export default function useSubscription(placeId: string) {
  const { subscriptionStatus } = useAppStore((state) => state.debugOptions);
  const { data: subscription, isLoading: isLoadingSubscription } = useQuery({
    queryKey: ["subscription", placeId],
    queryFn: () => getSubscriptionRequest(placeId),
    refetchOnMount: false,
  });
  const modifiedSubscription = subscription;

  if (subscriptionStatus && modifiedSubscription) {
    modifiedSubscription.status = subscriptionStatus;
  }
  return { subscription: modifiedSubscription, isLoadingSubscription };
}
