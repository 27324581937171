import { Alert } from "common/components/base";

export default function AlertMessage({
  hasTokens,
  isTrial,
}: {
  hasTokens: boolean;
  isTrial: boolean;
}) {
  if (hasTokens || isTrial) {
    const alertText = hasTokens
      ? "When you downgrade your plan, the difference in cost converts into tokens at $1 per token. You can use these tokens for future upgrades or payments"
      : "Any changes made to your current settings will be treated as a custom Pro Plan";
    return <Alert variant="default" className="my-4" text={alertText} />;
  }
}
