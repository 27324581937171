import { ReactNode, useMemo, useState } from "react";
import {
  Badge,
  Button,
  Pagination,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "common/components/base";
import {
  SortDescriptor,
  SortDirection,
  Table,
  TableBody,
} from "react-aria-components";
import { Keyword } from "common/types.ts";
import Trash from "fontawesome/regular/trash-can.svg?react";

import ArrowRight from "fontawesome/regular/arrow-right.svg?react";
import { useNavigate } from "@tanstack/react-router";
import DeleteKeywordButton from "@/src/pages/home/ManageKeywords/DeleteKeywordButton.tsx";
import AddKeyword from "@/src/pages/home/ManageKeywords/AddKeyword.tsx";
import { usePagination } from "common/hooks";

const columns = [
  {
    name: "Keyword",
    id: "label",
    isRowHeader: true,
    allowsSorting: true,
    className: "w-4/12",
  },
  { name: "Keyword difficulty", id: "difficulty", allowsSorting: true },
  { name: "Search volume", id: "search_volume", allowsSorting: true },
  { id: "actions", className: "w-28" },
];
type NewKeyword = Pick<Keyword, "label" | "id"> & { isCustom: true };
export default function KeywordsTable({
  keywords,
  keywordSuggestions,
  maxKeywords,
  closeModal,
  searchText,
}: {
  keywords: Keyword[];
  closeModal: () => void;
} & (
  | {
      keywordSuggestions?: never;
      maxKeywords?: never;
      searchText?: never;
    }
  | {
      keywordSuggestions: Keyword[];
      maxKeywords: number;
      searchText: string;
    }
)) {
  const navigate = useNavigate({ from: "/$placeId/home" });
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: "search_volume",
    direction: "descending",
  });

  const tableData = useMemo(() => {
    const data: (Keyword | NewKeyword)[] = [
      ...(keywordSuggestions || keywords),
    ].sort((a, b) => {
      const key = sortDescriptor.column as keyof Keyword;
      const aValue = a[key];
      const bValue = b[key];
      if (aValue === null || bValue === null) {
        return 0;
      }
      if (sortDescriptor.direction === "ascending") {
        return aValue > bValue ? 1 : -1;
      }
      return aValue < bValue ? 1 : -1;
    });
    if (
      keywordSuggestions &&
      searchText &&
      !keywordSuggestions.some(
        ({ label }) => label.toLowerCase() === searchText,
      )
    ) {
      data.unshift({ id: "custom", isCustom: true, label: searchText });
    }
    return data;
  }, [sortDescriptor, searchText, keywordSuggestions, keywords]);

  const { paginationComponentProps, pageItems, isPaginationVisible } =
    usePagination(5, tableData, [sortDescriptor, searchText]);

  return (
    <div className="hidden flex-1 flex-col lg:flex">
      <Table
        aria-label="Keywords"
        sortDescriptor={sortDescriptor}
        onSortChange={(descriptor) =>
          setSortDescriptor({
            column: descriptor.column as keyof Keyword,
            direction: descriptor.direction as SortDirection,
          })
        }
      >
        <TableHeader columns={columns}>
          {({ name, isRowHeader, allowsSorting, className }) => (
            <TableColumn
              isRowHeader={isRowHeader}
              key={name}
              allowsSorting={keywordSuggestions ? false : allowsSorting}
              className={className}
            >
              {name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody>
          {pageItems.map((keyword) => {
            const { id, label } = keyword;
            const isCustomKeyword = "isCustom" in keyword;
            let difficultyContent: ReactNode = "N/A";
            let searchVolumeContent = "N/A";

            if (!isCustomKeyword) {
              const { state, search_volume, difficulty } = keyword;
              if (state === "ready" && search_volume && difficulty) {
                difficultyContent = (
                  <div className="flex">
                    <Badge variant="grey" size="sm">
                      {difficulty}
                    </Badge>
                  </div>
                );
                searchVolumeContent = search_volume.toString();
              }
            }
            const addedKeywordId = keywords.find(
              (keyword) => keyword.label === label,
            )?.id;
            return (
              <>
                <TableRow className="h-16" key={id}>
                  <TableCell className="font-medium">{label}</TableCell>
                  <TableCell>{difficultyContent}</TableCell>
                  <TableCell>{searchVolumeContent}</TableCell>
                  <TableCell>
                    <div className="flex items-center gap-x-2">
                      {keywordSuggestions ? (
                        <AddKeyword
                          isLimitReached={keywords.length >= maxKeywords}
                          newKeywordData={isCustomKeyword ? { label } : { id }}
                          addedKeywordId={addedKeywordId}
                          closeModal={closeModal}
                        />
                      ) : (
                        <>
                          <DeleteKeywordButton
                            keywordId={id}
                            renderDeleteButton={(onPress) => (
                              <Button
                                variant="transparent"
                                size="lg"
                                isIconOnly
                                onPress={onPress}
                                isDisabled={keywords.length === 1}
                              >
                                <Trash />
                              </Button>
                            )}
                          />
                          <Button
                            variant="transparent"
                            size="lg"
                            isIconOnly
                            onPress={() => {
                              navigate({
                                search: (prevSearch) => ({
                                  ...prevSearch,
                                  selectedKeywordId: id,
                                }),
                              });
                              closeModal && closeModal();
                            }}
                          >
                            <ArrowRight />
                          </Button>
                        </>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
      {isPaginationVisible && (
        <Pagination
          size="sm"
          className="mx-auto mt-auto"
          {...paginationComponentProps}
        />
      )}
    </div>
  );
}
