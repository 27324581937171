import {
  Button,
  ButtonGroup,
  DialogHeading,
  Modal,
} from "common/components/base";

import RotateRight from "fontawesome/solid/rotate-right.svg?react";
import { useEffect, useState } from "react";
import PlanDetailsSelection from "./PlanDetailsSelection.tsx";
import {
  BillingFrequency,
  PaidPlaceSettings,
  PlanSettings,
} from "common/types.ts";
import useAllSettings from "common/datahooks/useAllSettings.ts";
import PlanPrice from "common/components/PlanCustomization/PlanPrice.tsx";
import { useTwBreakpoint } from "common/hooks";
import { PaymentSourcePreview } from "common/components/PaymentSourcePreview";
import { clsx } from "clsx";
import usePlace from "common/datahooks/usePlace.tsx";
import { useParams } from "@tanstack/react-router";
import useSubscriptionMutations from "common/datahooks/useSubscriptionMutations.tsx";
import Success from "common/components/PlanCustomization/Success.tsx";
import useSubscription from "common/datahooks/useSubscription.ts";
import UpdatePaymentMethod from "common/components/PlanCustomization/UpdatePaymentMethod.tsx";
import { typedObjectEntries } from "common/utils.ts";
import DesktopLayout from "common/components/PlanCustomization/DesktopLayout.tsx";
import MobileLayout from "common/components/PlanCustomization/MobileLayout.tsx";

const paymentDurations = [
  { key: "month", content: "Monthly" },
  {
    key: "year",
    content: (
      <span>
        Yearly
        <span className="ml-1 text-xs text-purple-500">2 months off</span>
      </span>
    ),
  },
] as const;

export function PlanCustomization({
  isOpen,
  setIsOpen,
  initialSettings,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  initialSettings?: PaidPlaceSettings;
}) {
  const { placeId } = useParams({ from: "/$placeId" });
  const isDesktop = useTwBreakpoint("lg");
  const [selectedPlanDetails, setSelectedPlanDetails] =
    useState<PlanSettings>();
  const [selectedPaymentSourceId, setSelectedPaymentSourceId] =
    useState<string>();
  const [isUpdatingPaymentMethod, setIsUpdatingPaymentMethod] = useState(false);
  const [billingFrequency, setBillingFrequency] =
    useState<BillingFrequency>("month");
  const { place } = usePlace(placeId);
  const { allSettings } = useAllSettings();
  const { subscription } = useSubscription(placeId);
  const {
    updateSubscriptionPlan,
    isUpdatingSubscriptionPlan,
    isUpdatingSubscriptionPlanSuccess,
    resetUpdateSubscriptionPlan,
  } = useSubscriptionMutations();

  function resetSettings() {
    if (place) {
      const { grid_size, keyword_count, drives, schedule } = place.settings;
      setSelectedPlanDetails({
        grid_size,
        keyword_count,
        drives,
        schedule,
      });
    }
    if (subscription) {
      setBillingFrequency(subscription.period);
    }
  }
  function updatePlan() {
    if (selectedPlanDetails && selectedPaymentSourceId) {
      updateSubscriptionPlan({
        billingFrequency,
        placeId,
        planSettings: selectedPlanDetails,
        paymentSourceId: selectedPaymentSourceId,
      });
    }
  }

  useEffect(() => {
    if (subscription) {
      setSelectedPaymentSourceId(subscription.payment_source_id);
    }
  }, [subscription]);

  useEffect(() => {
    if (initialSettings && place && isOpen) {
      setSelectedPlanDetails({
        keyword_count: place.settings.keyword_count,
        ...initialSettings,
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (place) {
      resetSettings();
    }
  }, [place]);

  const hasChanges =
    selectedPlanDetails && place && subscription
      ? typedObjectEntries(selectedPlanDetails).some(([key, value]) => {
          return key === "schedule"
            ? value.length !== place.settings.schedule.length
            : place.settings[key] !== value;
        }) || billingFrequency !== subscription.period
      : false;

  function renderContent({ close }: { close: () => void }) {
    const Component = isDesktop ? DesktopLayout : MobileLayout;

    return (
      <Component
        paymentMethodComponent={
          isUpdatingPaymentMethod ? (
            <UpdatePaymentMethod
              onClose={() => setIsUpdatingPaymentMethod(false)}
              setPaymentSource={(paymentSourceId) => {
                setSelectedPaymentSourceId(paymentSourceId);
                setIsUpdatingPaymentMethod(false);
              }}
            />
          ) : undefined
        }
        successComponent={
          isUpdatingSubscriptionPlanSuccess ? (
            <Success onClose={close} />
          ) : undefined
        }
        customizeComponent={
          <>
            <div className="mb-4 flex items-center justify-between max-lg:ml-12 max-lg:py-1.5 lg:mb-6">
              <DialogHeading className="text-2xl font-medium lg:font-semibold">
                Customize your plan
              </DialogHeading>
              <Button
                variant="white"
                size="sm"
                onPress={resetSettings}
                isDisabled={!hasChanges}
                isIconOnly={!isDesktop}
              >
                <RotateRight />
                {isDesktop && "Reset changes"}
              </Button>
            </div>
            <ButtonGroup
              items={paymentDurations}
              selectedKey={billingFrequency}
              setSelectedKey={setBillingFrequency}
              className="mb-6"
            />
            <PlanDetailsSelection
              selectedPlanDetails={selectedPlanDetails}
              setSelectedPlanDetails={setSelectedPlanDetails}
              allSettings={allSettings}
            />
          </>
        }
        summaryComponent={
          <>
            <span className="mb-4 text-2xl font-medium max-lg:ml-12 max-lg:py-1.5 lg:mb-3 lg:text-xl lg:font-semibold">
              Summary
            </span>
            <PaymentSourcePreview
              paymentSourceId={selectedPaymentSourceId}
              onUpdatePaymentSource={() => setIsUpdatingPaymentMethod(true)}
              isLoading={false}
            />
            <PlanPrice
              billingFrequency={billingFrequency}
              selectedPlanDetails={selectedPlanDetails}
              allSettings={allSettings}
            />
            <Button
              className="max-lg:mt-auto lg:ml-auto"
              isDisabled={
                !selectedPaymentSourceId ||
                (!hasChanges && subscription?.status !== "cancelled")
              }
              onPress={updatePlan}
              variant="black"
              isLoading={isUpdatingSubscriptionPlan}
              size="lg"
            >
              Update subscription
            </Button>
          </>
        }
      />
    );
  }

  return (
    <Modal
      variant={isUpdatingPaymentMethod || !isDesktop ? "white" : "grey"}
      onAfterClose={() => {
        resetSettings();
        setIsUpdatingPaymentMethod(false);
        resetUpdateSubscriptionPlan();
      }}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className={clsx(
        "flex-col",
        isUpdatingPaymentMethod && "lg:h-[540px]",
        isUpdatingPaymentMethod || isUpdatingSubscriptionPlanSuccess
          ? "lg:w-[500px]"
          : "lg:w-[880px]",
      )}
    >
      {renderContent}
    </Modal>
  );
}
