import { Badge, BadgeProps } from "common/components/base";
import { Snapshot } from "common/types.ts";

import ArrowUp from "fontawesome/solid/arrow-up.svg?react";
import ArrowDown from "fontawesome/solid/arrow-down.svg?react";
import Minus from "fontawesome/solid/minus.svg?react";

const stats: {
  label: string;
  valueKey: "average_rank" | "difficulty" | "search_volume";
}[] = [
  { label: "Keyword Difficulty", valueKey: "difficulty" },
  { label: "Search Volume", valueKey: "search_volume" },
  { label: "Avg. Rank", valueKey: "average_rank" },
];
export default function SnapshotStats({
  selectedSnapshot,
  compareWithSnapshot,
}: {
  selectedSnapshot: Snapshot | undefined;
  compareWithSnapshot: Snapshot | undefined;
}) {
  return (
    <div className="pointer-events-auto flex flex-col divide-y divide-grey-300 rounded-5xl bg-white lg:flex-row lg:divide-x lg:divide-y-0 lg:px-4.5 lg:py-3.5">
      {stats.map(({ label, valueKey }, index) => {
        let deltaComponent;
        if (selectedSnapshot && compareWithSnapshot) {
          const selectedValue = selectedSnapshot[valueKey];
          const compareWithValue = compareWithSnapshot[valueKey];

          const deltaValue =
            selectedValue && compareWithValue
              ? ((compareWithValue - selectedValue) / Math.abs(selectedValue)) *
                100
              : 0;
          let badgeVariant: BadgeProps["variant"] = "grey";
          let icon = <Minus />;

          if (deltaValue > 0) {
            badgeVariant = "green";
            icon = <ArrowUp />;
          } else if (deltaValue < 0) {
            badgeVariant = "red";
            icon = <ArrowDown />;
          }

          deltaComponent = !!deltaValue && (
            <Badge
              size="md"
              variant={badgeVariant}
              className="max-lg:ml-2 lg:mt-2"
            >
              {deltaValue === 0 ? deltaValue : deltaValue.toFixed(2)}%{icon}
            </Badge>
          );
        }
        return (
          <div
            key={index}
            className="flex flex-1 flex-row items-center py-3 lg:flex-col lg:px-4 lg:py-0"
          >
            <span className="text-sm lg:text-xs lg:font-medium lg:text-grey-500">
              {label}
            </span>
            {selectedSnapshot ? (
              <div className="flex items-center gap-x-1 max-lg:ml-auto lg:mb-2 lg:mt-8 lg:gap-x-1.5">
                <span className="text-lg font-medium lg:text-2xl">
                  {selectedSnapshot[valueKey] === null ? (
                    <Badge size="md" variant="grey">
                      Unavailable
                    </Badge>
                  ) : (
                    selectedSnapshot[valueKey]
                  )}
                </span>
                {compareWithSnapshot && (
                  <div className="flex gap-x-1.5 text-sm text-grey-500 lg:text-lg">
                    |{" "}
                    {compareWithSnapshot[valueKey] === null ? (
                      <Badge size="md" variant="grey">
                        Unavailable
                      </Badge>
                    ) : (
                      compareWithSnapshot[valueKey]
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="h-7 w-24 animate-pulse rounded-xl bg-grey-300 max-lg:ml-auto lg:mb-2 lg:mt-8 lg:h-8" />
            )}
            {deltaComponent}
          </div>
        );
      })}
    </div>
  );
}
