import { Button } from "common/components/base";
import { useAppStore } from "common/hooks";
import CircleInfo from "fontawesome/solid/circle-info.svg?react";
import { getMonthName } from "common/utils.ts";
import { fromAbsolute, getLocalTimeZone } from "@internationalized/date";
import { useSelectedPlace } from "@/src/contexts.ts";

export default function Banner() {
  const {
    selectedPlace: { subscription },
    isDemoMode,
  } = useSelectedPlace();

  const isTrial = subscription && subscription.status === "in_trial";
  const isCancelled = subscription && subscription.status === "cancelled";

  let bannerText, bannerButton;

  if (isDemoMode) {
    bannerText =
      "One more step! You’re currently exploring our demo. To start the free trial, add your GBP profile!";
    bannerButton = (
      <Button
        size="sm"
        variant="green"
        onPress={() => useAppStore.getState().setIsAddModalOpen(true)}
      >
        Start now
      </Button>
    );
  } else if (isTrial) {
    const nextBillingDate = fromAbsolute(
      subscription.next_billing_at,
      getLocalTimeZone(),
    );
    bannerText = `Your free trial will end on ${getMonthName(nextBillingDate)} ${nextBillingDate.day} but you can start custom plan now if you like`;
    bannerButton = (
      <Button
        size="sm"
        variant="purple"
        onPress={() => useAppStore.getState().setIsPlanCustomizationOpen(true)}
      >
        Upgrade now
      </Button>
    );
  } else if (isCancelled) {
    const cancellationDate = fromAbsolute(
      subscription.cancelled_at,
      getLocalTimeZone(),
    );

    bannerText = `Your subscription was cancelled on ${getMonthName(cancellationDate)} ${cancellationDate.day}. To continue using HeyRank, simply resubscribe`;
    bannerButton = (
      <Button
        size="sm"
        variant="green"
        onPress={() => useAppStore.getState().setIsPlanCustomizationOpen(true)}
      >
        Subscribe again
      </Button>
    );
  }
  if (isDemoMode || isTrial || isCancelled) {
    return (
      <>
        <div className="mx-auto flex items-center gap-x-2 rounded-full border border-grey-200 bg-white p-3 shadow-lg lg:gap-x-3 lg:pr-2.5 2xl:h-12">
          <CircleInfo className="size-3.5 lg:size-4.5" />
          <span className="text-xs text-grey-700 lg:text-sm">{bannerText}</span>
          {bannerButton}
        </div>
      </>
    );
  }
}
